import logo from "./logo.svg";
import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from 'react';
import { subscribeUserToEmail } from "./services/SubscribeService";

const App = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const coverKey = queryParameters.get("coverKey");
  const patientID = queryParameters.get("patientID");
  const initialSubscribe = queryParameters.get("subscribe") === 'true'; 
  const source = queryParameters.get("source");

  const [subscribe, setSubscribe] = useState(initialSubscribe);
  const [message, setMessage] = useState("unsubscribed from our list.");
  
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fafafc",
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
    }),
  }));

  useEffect(() => {
    const handleUnsubscribe = async () => {
      if (coverKey && patientID && subscribe !== null) {
        try {
          const data = await subscribeUserToEmail({ coverKey, patientID, subscribe, source });
          setMessage(subscribe ? "resubscribed from our list." : "unsubscribed from our list.");
        } catch (error) {
          console.error('Error during unsubscribe:', error);
        }
      }
    };

    handleUnsubscribe();
  }, [coverKey, patientID, subscribe, source]);

  const handleReSubscribe = async () => {
    setSubscribe('true')
  };


  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Item>
          <Box sx={{ borderRadius: "10px", borderColor: "black" }}>
            <Grid container spacing={0} sx={{ bgcolor: "#fafafc" }}>
              <Grid size={4} sx={{ textAlign: "right" }}>
                <img
                  src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/ae52a6e2-ca6a-4d8e-8944-532f952aae85/888080f6-2807-11ef-80b7-fe0024de756d/visionxlogo.jpg"
                  width="200px"
                />
              </Grid>
              <Grid size={8}>
                <img
                  src="https://d15k2d11r6t6rl.cloudfront.net/public/users/Integrators/ae52a6e2-ca6a-4d8e-8944-532f952aae85/888080f6-2807-11ef-80b7-fe0024de756d/visionxname.jpg"
                  height="200px"
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ bgcolor: "white" }}>
            <h1 style={{ paddingTop: "100px", color: "black" }}>
              Per your request, you have been <br />
              {message}
            </h1>

            <p
              className="font-weight-bold"
              style={{ paddingBottom: "20px", fontWeight: "bolder" }}
            >
              Powered by Helix - EssilorLuxotica &nbsp;&nbsp;{" "}
              <span 
                style={{ color: "#3c6ef2", cursor: "pointer", textDecoration: "underline" }} 
                onClick={handleReSubscribe}
              >
                Resubscribe
              </span>
            </p>
          </Box>
        </Item>
      </Container>
    </React.Fragment>
  );
};

export default App;
