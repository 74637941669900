const API_URL = process.env.REACT_APP_API_URL;

export const subscribeUserToEmail = async ({ coverKey, patientID, subscribe, source }) => {
  try {
    const API_URL = process.env.REACT_APP_UNSUBS_URL;
    const response = await fetch(`${API_URL}/EmailSubscribe?secretCode=ditac-1`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        coverKey: parseInt(coverKey, 10), 
        patientID,
        subscribe: subscribe === 'true', 
        source,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to unsubscribe');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during unsubscribe:', error);
    throw error; 
  }
};